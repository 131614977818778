import React from "react"
import { graphql } from "gatsby"
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { 
   Center,
   HStack,
   Box, 
   Link, 
   Text, 
   Image, 
   Spacer
} from "@chakra-ui/react"
import Layout from '../components/layout.js';
import Parser from "html-react-parser"
import {
   Midasi2,
   Pagenavi3,
   sumarrize
} from '../util/functions.js';

const BlogIndex = ({data, path, pageContext}) => {

   return (
      <Layout 
         title={pageContext.slugname + "一覧"}
         description={"TUBESEARCH " + pageContext.slugname + "一覧を掲載しています"}
         // image={getImage(icatch)}
         uri={path}
         canonicalUri="https://yt-search.net/all/1"
      >
         <Midasi2>{pageContext.slugname}一覧</Midasi2>
         {
            React.Children.toArray(data.allWpPost.nodes.map(post => {
               const id = post.id;
               const title = post.title;
               const uri = post.uri;
               const date = post.date;
               const excerpt = sumarrize(post.excerpt
                  .replace("[toc]","")
                  .replace("[holl_befor_sentence]","")
                  .replace("[pachi_befor_sentence]",""));
               const authorname = post.author.node.name;
               let tagnames = post.tags.nodes.map(node =>{
                  return node.name;
               });
               
               return (
                  
               <>
               <Box p={"1rem"} m="0.5rem" bg="#fff" display={{ md: "flex" }}>
                  {post.featuredImage &&
                  <Center>
                     
                     <Image
                        as={GatsbyImage}
                        // fluid={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                        // image={getImage(post.featuredImage.node.localFile.childImageSharp)}
                        image={post.featuredImage?.node.localFile.childImageSharp?.gatsbyImageData}
                        // src={
                        //    post.featuredImage ? 
                        //    post.featuredImage.node.localFile.childImageSharp ?
                        //    post.featuredImage.node.localFile.childImageSharp.fluid.src 
                        //             : 
                        //             post.featuredImage.node.localFile.publicURL ?
                        //             post.featuredImage.node.localFile.publicURL
                        //                :
                        //                   ""
                        //          : 
                        //             ""
                        //    }
                        borderRadius="lg"
                        // boxSize="90vw"
                        w={{base:"90vw",md:"30vw"}} 
                        h={{base:"50vw",md:"20vw"}}
                        alt={post.featuredImage.node.altText}
                     />
                  </Center>
                  }
                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                     <HStack >
                        <Center>
                        {tagnames &&
                           React.Children.toArray(tagnames.map(tagname=> {
                              return (
                              <Text mx={2} fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                              {tagname}
                              </Text>
                              )
                           }))
                        }
                        </Center>
                        <Spacer />
                        <Box>
                           <Text align="right" fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                              {date}
                           </Text>
                        </Box>
                     </HStack>
                     <Link
                        as={GatsbyLink}
                        to={uri}
                        mt={1}
                        display="block"
                        fontSize="lg"
                        lineHeight="normal"
                        fontWeight="semibold"
                     >
                        {title}
                     </Link>
                     <Text mt={2} color="gray.500">
                        {Parser(excerpt,{trim:true})}
                     </Text>
                  </Box>
               </Box>
               </>
               )
            }))
         }
         <Pagenavi3 pageContext={pageContext} />

      </Layout>
   )
}

export default BlogIndex

export const pageQuery = graphql`
  query($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ){
      nodes {
        id
        title
        uri
        date(formatString: "YYYY.MM.DD")
        excerpt
        terms {
          nodes {
            name
            slug
            nodeType
          }
        }
        author {
          node {
            name
            slug
          }
        }
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
           localFile {
             childImageSharp {
               fluid(maxWidth: 1000, quality: 100) {
                 ...GatsbyImageSharpFluid_tracedSVG
               }
               gatsbyImageData
             }
             publicURL
            }
          }
        }
      }
    }
  }
`